import { combineReducers } from "redux";
import { customerAddReducer, customerReducer } from "./customerReducer";
import { authLoginReducer } from "./authReducer";
import {
  beautyExpertAddReducer,
  deleteBeautyExpertReducer,
  getAllBeautyExpertReducer,
} from "./beautyExpertReducer";
import {
  addBeautyServiceReducer,
  deleteBeautyServiceReducer,
  getAllBeautyServiceReducer,
} from "./beautyServiceReducer";
import {
  beautyExpertToServiceAddReducer,
  beautyExpertToServiceGetAllReducer,
} from "./beautyExpertToServiceReducer";
import {
  addServiceOrderReducer,
  deleteServiceOrderReducer,
  getAllServiceOrdersReducer,
} from "./serviceOrderReducer";
import {
  addServiceOrderDetailReducer,
  deleteServiceOrderDetailReducer,
  getAllServiceOrderDetailsReducer,
} from "./ServiceOrderDetailReducer";
import { addOutGoingReducer, deleteOutGoingReducer, getAllOutGoingReducer, getOutGoingReducer, updateOutGoingReducer } from "./outGoingReducer";
import { addRentRoomReducer, deleteRentRoomReducer, getAllRentRoomReducer, getRentRoomReducer, updateRentRoomReducer } from "./rentRoomReducer";
import { getAllPaymentMethodReducer } from "./paymentMethodReducer";
import { addCourseReducer, deleteCourseReducer, getAllCourseReducer, getCourseReducer, updateCourseReducer } from "./courseReducer";
import { addDeptReducer, deleteDeptReducer, getAllDeptReducer, getDeptReducer, updateDeptReducer } from "./deptReducer";
import { addReservationReducer, deleteReservationReducer, getAllReservationReducer, getReservationReducer, updateReservationReducer } from "./reservationReducer";
import { addFinanceReportReducer, deleteFinanceReportReducer, getAllFinanceReportReducer } from "./financeReportReducer";
import { addHairDyeReducer, deleteHairDyeReducer, getAllHairDyeReducer } from "./hairDyeReducer";
import { addSaleHairDyeReducer, deleteSaleHairDyeReducer, getAllSaleHairDyeReducer , getSaleHairDyeReducer, updateSaleHairDyeReducer} from "./saleHairDyeReducer";


export const rootReducer = combineReducers({
  authLogin: authLoginReducer,
  customers: customerReducer,
  addCustomer: customerAddReducer,
  getBeautyExperts: getAllBeautyExpertReducer,
  addBeautyExpert: beautyExpertAddReducer,
  getBeautyServices: getAllBeautyServiceReducer,
  addBeautyService: addBeautyServiceReducer,
  addBeautyExpertToService: beautyExpertToServiceAddReducer,
  getAllBeautyExpertToServices: beautyExpertToServiceGetAllReducer,
  getAllServiceOrders: getAllServiceOrdersReducer,
  addServiceOrder: addServiceOrderReducer,
  addServiceOrderDetail: addServiceOrderDetailReducer,
  getAllServiceOrderDetails: getAllServiceOrderDetailsReducer,
  addOutGoing: addOutGoingReducer,
  getAllOutGoings: getAllOutGoingReducer,
  addRentRoom: addRentRoomReducer,
  getAllRentRooms: getAllRentRoomReducer,
  deleteOrder: deleteServiceOrderReducer,
  deleteOrderDetail: deleteServiceOrderDetailReducer,
  deleteOutGoing: deleteOutGoingReducer,
  deleteBeautyService: deleteBeautyServiceReducer,
  deleteBeautyExpert: deleteBeautyExpertReducer,
  paymentMethods: getAllPaymentMethodReducer,
  courses: getAllCourseReducer,
  addCourse: addCourseReducer,
  deleteCourse: deleteCourseReducer,
  allDept: getAllDeptReducer,
  addDept: addDeptReducer,
  deleteDept: deleteDeptReducer,
  updateDept: updateDeptReducer,
  getDept: getDeptReducer,
  updateOutGoing: updateOutGoingReducer,
  getOutGoing: getOutGoingReducer,
  updateCourse: updateCourseReducer,
  getCourse: getCourseReducer,
  updateRentRoom: updateRentRoomReducer,
  getRentRoom: getRentRoomReducer,
  deleteRentRoom: deleteRentRoomReducer,
  addReservation: addReservationReducer,
  getAllReservation: getAllReservationReducer,
  updateReservation: updateReservationReducer,
  getReservation: getReservationReducer,
  deleteReservation: deleteReservationReducer,
  allFinanceReport: getAllFinanceReportReducer,
  addFinanceReport: addFinanceReportReducer,
  deleteFinanceReport: deleteFinanceReportReducer,
  allHairDye: getAllHairDyeReducer,
  addHairDye: addHairDyeReducer,
  deleteHairDye: deleteHairDyeReducer,
  allSaleHairDye: getAllSaleHairDyeReducer,
  getSaleHairDye: getSaleHairDyeReducer,
  addSaleHairDye: addSaleHairDyeReducer,
  updateSaleHairDye: updateSaleHairDyeReducer,
  deleteSaleHairDye: deleteSaleHairDyeReducer,
});
