import { ADD_SALE_HAIR_DYE_ERROR, ADD_SALE_HAIR_DYE_START, ADD_SALE_HAIR_DYE_SUCCESS, DELETE_SALE_HAIR_DYE_ERROR, DELETE_SALE_HAIR_DYE_START, DELETE_SALE_HAIR_DYE_SUCCESS, GET_ALL_SALE_HAIR_DYE_ERROR, GET_ALL_SALE_HAIR_DYE_START, GET_ALL_SALE_HAIR_DYE_SUCCESS, GET_SALE_HAIR_DYE_ERROR, GET_SALE_HAIR_DYE_START, GET_SALE_HAIR_DYE_SUCCESS, UPDATE_SALE_HAIR_DYE_ERROR, UPDATE_SALE_HAIR_DYE_START, UPDATE_SALE_HAIR_DYE_SUCCESS } from "../actionTypes/saleHairDyeActionTypes";



const ADD_INITIAL_DATA = {
    saleHairDye: {},
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const addSaleHairDyeReducer = (
    state = ADD_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case ADD_SALE_HAIR_DYE_START:
        return { ...state, isLoading: true, error: "" };
      case ADD_SALE_HAIR_DYE_SUCCESS:
        return { ...state, saleHairDye: payload, isLoading: false };
      case ADD_SALE_HAIR_DYE_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };

  const UPDATE_INITIAL_DATA = {
    saleHairDye: {},
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const updateSaleHairDyeReducer = (
    state = UPDATE_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case UPDATE_SALE_HAIR_DYE_START:
        return { ...state, isLoading: true, error: "" };
      case UPDATE_SALE_HAIR_DYE_SUCCESS:
        return { ...state, saleHairDye: payload, isLoading: false };
      case UPDATE_SALE_HAIR_DYE_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };


  const GET_INITIAL_DATA = {
    saleHairDyeId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const getSaleHairDyeReducer = (state= GET_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case GET_SALE_HAIR_DYE_START : return {...state, isLoading:true, error:""}
      case GET_SALE_HAIR_DYE_SUCCESS : return {...state, saleHairDyeId:payload, isLoading:false}
      case GET_SALE_HAIR_DYE_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }

  
  const ALL_INITIAL_DATA = {
    allSaleHairDye: [],
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const getAllSaleHairDyeReducer = (
    state = ALL_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case GET_ALL_SALE_HAIR_DYE_START:
        return { ...state, isLoading: true, error: "" };
      case GET_ALL_SALE_HAIR_DYE_SUCCESS:
        return { ...state, allSaleHairDye: payload, isLoading: false };
      case GET_ALL_SALE_HAIR_DYE_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  
  const DELETE_INITIAL_DATA = {
    id:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const deleteSaleHairDyeReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case DELETE_SALE_HAIR_DYE_START : return {...state, isLoading:true, error:""}
      case DELETE_SALE_HAIR_DYE_SUCCESS : return {...state, id:payload, isLoading:false}
      case DELETE_SALE_HAIR_DYE_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }
