import React, { useEffect } from "react";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { connect } from "react-redux";
import { deleteFunc } from "../../constants/delete/DeleteForm";
import { useFormik } from "formik";
import { getRoleAdmin } from "../../constants/role/getToken";
import { deleteHairDyeAction, getAllHairDyeAction } from "../../redux/actions/hairDyeAction";

const AllHairDye = (props) => {
  const roleAdmin = getRoleAdmin();
  useEffect(() => {
    return props.getAllHairDyeAction();
  }, []);
  
  const dataTableHead = [
    "No",
    "Ad",
    "Kodu",
    "Firma",
    "Qiymət",
    "",
  ];

  const getIdFunc = (id) => {
    const myData = {
      id: id,
    };
    deleteFunc({
      deleteActionName: props.deleteHairDyeAction(myData),
      locationReplaceName: "/allHairDye",
    });
  };
//   const { handleChange, handleSubmit, handleBlur } = useFormik({
//     initialValues: {
//       monthFilter: "",
//       filterDay:"",
//     },

//     onSubmit: (values) => {
//       const data = {
//         monthFilter: values.monthFilter,
//         filterDay:values.filterDay,
//       };
//       props.getAllFinanceReportAction(data);
//     },
//   });
  return (
    <div className="allHairDye">
      <SubHeader
        backLink="/"
        title="Boya siyahısı"
        btnTitle="boya əlavə et"
        btnLink="/addHairDye"
      />
      <div className="container-fluid">
        {/* <form
          style={{ marginBottom: "30px", marginTop: "20px" }}
          onSubmit={handleSubmit}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                <input
                  style={{
                    marginTop: "12px",
                    padding: "10px 25px",
                    borderRadius: "8px",
                    outline: "none",
                    border: "0.8px solid #ccc",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="monthFilter"
                  name="monthFilter"
                  type="month"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                <input
                  style={{
                    marginTop: "12px",
                    padding: "10px 25px",
                    borderRadius: "8px",
                    outline: "none",
                    border: "0.8px solid #ccc",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="filterDay"
                  name="filterDay"
                  type="date"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                <button
                  className="btn btn-secondary"
                  style={{ marginTop: "15px", padding: "10px 20px" }}
                  type="submit"
                >
                  Filter et
                </button>
              </div>
            </div>
          </div>
        </form> */}
        <div className="row">
          {props.getAllHairDye.isLoading ? (
            <LoadingComponent />
          ) : props.getAllHairDye.allHairDye?.data == null || props.getAllHairDye.allHairDye?.data.length==0 ? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center my-5">
              <EmptyTableComponent />
            </div>
          ) : (
            <DataTableComponent
              tableHeadData={dataTableHead}
            >
              {props.getAllHairDye.allHairDye?.data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.productName}</td>
                  <td>{item.dyeCode}</td>
                  <td>{item.firmName}</td>
                  <td>{item.unitPrice} AZN</td>
                  {
                    roleAdmin =="admin" ?<ModifyButtonsComponent
                      delFunc={() => getIdFunc(item.id)}
                    //   linkUrl={`/UpdateOutGoing/${item.id}`}
                    />:null
                  }
                </tr>
              ))}
            </DataTableComponent>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllHairDye: state.allHairDye,
    deleteHairDye: state.deleteHairDye,
  };
};

export default connect(mapStateToProps, {
    getAllHairDyeAction,
    deleteHairDyeAction,
})(AllHairDye);
