import axios from "axios";
import { getToken } from "../../constants/role/getToken";
import { config } from "../../api/configApi";
import { baseUrl } from "../../api/baseApi";
import {
  ADD_SALE_HAIR_DYE_ERROR,
  ADD_SALE_HAIR_DYE_START,
  ADD_SALE_HAIR_DYE_SUCCESS,
  DELETE_SALE_HAIR_DYE_ERROR,
  DELETE_SALE_HAIR_DYE_START,
  DELETE_SALE_HAIR_DYE_SUCCESS,
  GET_ALL_SALE_HAIR_DYE_ERROR,
  GET_ALL_SALE_HAIR_DYE_START,
  GET_ALL_SALE_HAIR_DYE_SUCCESS,
  GET_SALE_HAIR_DYE_ERROR,
  GET_SALE_HAIR_DYE_START,
  GET_SALE_HAIR_DYE_SUCCESS,
  UPDATE_SALE_HAIR_DYE_ERROR,
  UPDATE_SALE_HAIR_DYE_START,
  UPDATE_SALE_HAIR_DYE_SUCCESS,
} from "../actionTypes/saleHairDyeActionTypes";

export const addSaleHairDyeAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_SALE_HAIR_DYE_START,
  });

  axios
    .post(`${baseUrl}/SaleHairDye/Add`, data, config)
    .then((response) => {
      dispatch({
        type: ADD_SALE_HAIR_DYE_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allSaleHairDye");
    })
    .catch((error) => {
      dispatch({
        type: ADD_SALE_HAIR_DYE_ERROR,
        payload: error,
      });
    });
};

export const updateSaleHairDyeAction = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SALE_HAIR_DYE_START,
  });

  axios
    .put(`${baseUrl}/SaleHairDye/Update`, data, config)
    .then((response) => {
      dispatch({
        type:UPDATE_SALE_HAIR_DYE_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allSaleHairDye");
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_SALE_HAIR_DYE_ERROR,
        payload: error,
      });
    });
};


export const getSaleHairDyeAction = (id) => (dispatch) => {
 
  dispatch({
    type: GET_SALE_HAIR_DYE_START,
  });

  axios
    .get(`${baseUrl}/SaleHairDye/Get/${id}`, null, config)
    .then((response) => {
      dispatch({
        type: GET_SALE_HAIR_DYE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALE_HAIR_DYE_ERROR,
        payload: error,
      });
    });
};



export const getAllSaleHairDyeAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_SALE_HAIR_DYE_START,
  });
  const configGetAll = {
    headers: {
      Authorization: getToken,
    },
    params: {
      expertName: data.expertName,
      productName: data.productName,
      filterMonth: data.filterMonth,
      filterDate: data.filterDate,
    },
  };
  axios
    .get(`${baseUrl}/SaleHairDye/GetAll`, configGetAll)
    .then((response) => {
      dispatch({
        type: GET_ALL_SALE_HAIR_DYE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_SALE_HAIR_DYE_ERROR,
        payload: error,
      });
    });
};

export const deleteSaleHairDyeAction = (data) => (dispatch) => {
  const configDelete = {
    params: {
      id: data.id,
    },
    headers: {
      Authorization: getToken,
    },
  };

  dispatch({
    type: DELETE_SALE_HAIR_DYE_START,
  });

  axios
    .put(`${baseUrl}/SaleHairDye/Remove`, null, configDelete)
    .then((response) => {
      dispatch({
        type: DELETE_SALE_HAIR_DYE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_SALE_HAIR_DYE_ERROR,
        payload: error,
      });
    });
};
