import React from "react";
import { Link } from "react-router-dom";

const ModifyButtonsComponent = (props) => {
  return (
    <td className={`${props.className} d-flex justify-content-evenly`}>
      <a href={props.linkUrl} style={{outline:"none", textDecoration:"none", border:"none",background:"transparent"}}>
        <img src="/images/edit.png" />
      </a>
      <button onClick={props.delFunc}  type="button" style={{outline:"none", border:"none",background:"transparent"}}>
        <img src="/images/delete.png" />
      </button>
    
    </td>
  );
};

export default ModifyButtonsComponent;
