import { useFormik } from "formik";
import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import ErrorText from "../../components/form/ErrorText";
import { connect } from "react-redux";
import { getBeautyExpertAllAction } from "../../redux/actions/beautyExpertAction";
import { addSaleHairDyeValidation} from "../../constants/yup/validation";
import MyInput from "../../components/form/MyInput";
import { addSaleHairDyeAction } from "../../redux/actions/saleHairDyeAction";
import { getAllHairDyeAction } from "../../redux/actions/hairDyeAction";

const AddSaleHairDye = (props) => {
  useEffect(() => {
    return props.getAllHairDyeAction(), props.getBeautyExpertAllAction();
  }, []);
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        hairDyeId: 0,
        expertId: 0,
        quantity: 0,
        expertPayPrice: 0,
        saloonPayPrice: 0,
        saleDate: new Date()
      },
      onSubmit: (values) => {
        
        const data = {
          hairDyeId: values.hairDyeId,
          expertId: values.expertId,
          quantity: values.quantity,
          expertPayPrice: values.expertPayPrice,
          saloonPayPrice: values.saloonPayPrice,
          saleDate : values.saleDate
        };
        props.addSaleHairDyeAction(data);
      },
      validationSchema: addSaleHairDyeValidation,
    }
  );
  return (
    <div className="saleHairDyeAdd">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addOrder.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni boya satışı əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                  btnIco={<AddTask />}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="expertId"
                        className="form-control"
                      >
                        <option hidden defaultChecked defaultValue="choose">
                          Expertlər
                        </option>

                        {props.getBeautyExperts?.beautyExperts?.data?.map(
                          (item, index) => (
                            <option
                              id="expertId"
                              name="expertId"
                              key={index}
                              value={item.id}
                            >
                              {item.firstName +
                                " " +
                                item.lastName +
                                " - " +
                                item.profession}
                            </option>
                          )
                        )}
                      </select>
                      <ErrorText
                        error={errors.expertId}
                        touched={touched.expertId}
                        errorMessage={errors.expertId}
                        name={"expertId"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="hairDyeId"
                        className="form-control"
                      >
                        <option hidden defaultChecked defaultValue="choose">
                          Boyalar
                        </option>

                        {props.getAllHairDye?.allHairDye?.data?.map(
                          (item, index) => (
                            <option
                              id="hairDyeId"
                              name="hairDyeId"
                              key={index}
                              value={item.id}
                            >
                              {item.productName +
                                " " +
                                item.dyeCode +
                                " - " +
                                item.unitPrice + " AZN"}
                            </option>
                          )
                        )}
                      </select>
                      <ErrorText
                        error={errors.hairDyeId}
                        touched={touched.hairDyeId}
                        errorMessage={errors.hairDyeId}
                        name={"hairDyeId"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div
                        style={{ marginTop: "-20px" }}
                        className="myInpCustomer"
                      >
                        <MyInput
                          id="quantity"
                          name="quantity"
                          inputType="text"
                          placeholder="say"
                          handleChangeFunc={handleChange}
                          handleBlurFunc={handleBlur}
                        />
                      </div>
                      <ErrorText
                        error={errors.quantity}
                        touched={touched.quantity}
                        errorMessage={errors.quantity}
                        name={"quantity"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div
                        style={{ marginTop: "-20px" }}
                        className="myInpCustomer"
                      >
                        <MyInput
                          id="expertPayPrice"
                          name="expertPayPrice"
                          inputType="text"
                          placeholder="ustanın ödədiyi məbləğ"
                          handleChangeFunc={handleChange}
                          handleBlurFunc={handleBlur}
                        />
                      </div>
                      <ErrorText
                        error={errors.expertPayPrice}
                        touched={touched.expertPayPrice}
                        errorMessage={errors.expertPayPrice}
                        name={"expertPayPrice"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div
                        style={{ marginTop: "-20px" }}
                        className="myInpCustomer"
                      >
                        <MyInput
                          id="saloonPayPrice"
                          name="saloonPayPrice"
                          inputType="text"
                          placeholder="salonun ödədiyi məbləğ"
                          handleChangeFunc={handleChange}
                          handleBlurFunc={handleBlur}
                        />
                      </div>
                      <ErrorText
                        error={errors.saloonPayPrice}
                        touched={touched.saloonPayPrice}
                        errorMessage={errors.saloonPayPrice}
                        name={"saloonPayPrice"}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addSaleHairDye: state.addSaleHairDye,
    getBeautyExperts: state.getBeautyExperts,
    getAllHairDye: state.allHairDye,

  };
};

export default connect(mapStateToProps, {
  addSaleHairDyeAction,
  getAllHairDyeAction,
  getBeautyExpertAllAction,
})(AddSaleHairDye);
