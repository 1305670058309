import axios from "axios";
import { getToken } from "../../constants/role/getToken";
import { config } from "../../api/configApi";
import { baseUrl } from "../../api/baseApi";
import { ADD_HAIR_DYE_ERROR, ADD_HAIR_DYE_START, ADD_HAIR_DYE_SUCCESS, DELETE_HAIR_DYE_ERROR, DELETE_HAIR_DYE_START, DELETE_HAIR_DYE_SUCCESS, GET_ALL_HAIR_DYE_ERROR, GET_ALL_HAIR_DYE_START, GET_ALL_HAIR_DYE_SUCCESS } from "../actionTypes/hairDyeActionTypes";

export const addHairDyeAction = (data) => (dispatch) => {
    dispatch({
      type: ADD_HAIR_DYE_START,
    });
  
    axios
      .post(`${baseUrl}/HairDye/Add`, data, config)
      .then((response) => {
        dispatch({
          type: ADD_HAIR_DYE_SUCCESS,
          payload: response.data,
        });
        window.location.replace("/allHairDye");
      })
      .catch((error) => {
        dispatch({
          type: ADD_HAIR_DYE_ERROR,
          payload: error,
        });
      });
  };
  
  export const getAllHairDyeAction = () => (dispatch) => {
    dispatch({
      type: GET_ALL_HAIR_DYE_START,
    });
    const configGetAll = {
      headers: {
        Authorization: getToken,
      },
    //   params: {
    //     monthFilter: data.monthFilter,
    //   },
    };
    axios
      .get(`${baseUrl}/HairDye/GetAll`, configGetAll)
      .then((response) => {
        dispatch({
          type: GET_ALL_HAIR_DYE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_HAIR_DYE_ERROR,
          payload: error,
        });
      });
  };
  
  export const deleteHairDyeAction = (data) => (dispatch) => {
    const configDelete = {
      params: {
        id: data.id,
      },
      headers: {
        Authorization: getToken,
      },
    };
  
    dispatch({
      type: DELETE_HAIR_DYE_START,
    });
  
    axios
      .put(`${baseUrl}/HairDye/Delete`, null, configDelete)
      .then((response) => {
        dispatch({
          type: DELETE_HAIR_DYE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_HAIR_DYE_ERROR,
          payload: error,
        });
      });
  };