import axios from "axios";
import { ADD_BEAUTY_EXPERT_ERROR, ADD_BEAUTY_EXPERT_START, ADD_BEAUTY_EXPERT_SUCCESS, DELETE_BEAUTY_EXPERT_ERROR, DELETE_BEAUTY_EXPERT_START, DELETE_BEAUTY_EXPERT_SUCCESS, GET_BEAUTY_EXPERT_ALL_ERROR, GET_BEAUTY_EXPERT_ALL_START, GET_BEAUTY_EXPERT_ALL_SUCCESS } from "../actionTypes/beautyExpertActionTypes";
import { baseUrl } from "../../api/baseApi";
import { getToken } from "../../constants/role/getToken";


export const getBeautyExpertAllAction = ()=> (dispatch) =>{
    const config = {
        headers: {
          Authorization: getToken,
        },
      };
      dispatch({
        type: GET_BEAUTY_EXPERT_ALL_START,
      });

      axios
      .get(`${baseUrl}/BeautyExpert/GetAllBeautyExperts`, config)
      .then((response) => {
        dispatch({
          type: GET_BEAUTY_EXPERT_ALL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_BEAUTY_EXPERT_ALL_ERROR,
          payload: error,
        });
      });
}

export const beautyExpertAddAction = (data) => dispatch => {
    const config = {
        headers: {
          Authorization: getToken,
        },
      };
      dispatch({
        type: ADD_BEAUTY_EXPERT_START,
      });

      axios.post(`${baseUrl}/BeautyExpert/AddBeautyExpert`, data, config).then(response =>{
        dispatch({
            type: ADD_BEAUTY_EXPERT_SUCCESS,
            payload:response.data
        });
        window.location.replace('/beautyExpert');
      }).catch((error) => {
        dispatch({
          type: ADD_BEAUTY_EXPERT_ERROR,
          payload: error,
        });
      
      });
}

export const deleteBeautyExpertAction = (data) => (dispatch) => {
  const configDelete = {
    params: {
      beautyExpertId: data.beautyExpertId,
    },
    headers: {
      Authorization: getToken,
    },
  };

  dispatch({
    type: DELETE_BEAUTY_EXPERT_START,
  });

  axios
    .put(`${baseUrl}/BeautyExpert/DeleteBeautyExpert`, null, configDelete)
    .then((response) => {
      dispatch({
        type: DELETE_BEAUTY_EXPERT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_BEAUTY_EXPERT_ERROR,
        payload: error,
      });
    });
};