import { ADD_HAIR_DYE_ERROR, ADD_HAIR_DYE_START, ADD_HAIR_DYE_SUCCESS, DELETE_HAIR_DYE_ERROR, DELETE_HAIR_DYE_START, DELETE_HAIR_DYE_SUCCESS, GET_ALL_HAIR_DYE_ERROR, GET_ALL_HAIR_DYE_START, GET_ALL_HAIR_DYE_SUCCESS } from "../actionTypes/hairDyeActionTypes";


const ADD_INITIAL_DATA = {
    hairDye: {},
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const addHairDyeReducer = (
    state = ADD_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case ADD_HAIR_DYE_START:
        return { ...state, isLoading: true, error: "" };
      case ADD_HAIR_DYE_SUCCESS:
        return { ...state, hairDye: payload, isLoading: false };
      case ADD_HAIR_DYE_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  const ALL_INITIAL_DATA = {
    allHairDye: [],
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const getAllHairDyeReducer = (
    state = ALL_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case GET_ALL_HAIR_DYE_START:
        return { ...state, isLoading: true, error: "" };
      case GET_ALL_HAIR_DYE_SUCCESS:
        return { ...state, allHairDye: payload, isLoading: false };
      case GET_ALL_HAIR_DYE_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  
  const DELETE_INITIAL_DATA = {
    id:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const deleteHairDyeReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case DELETE_HAIR_DYE_START : return {...state, isLoading:true, error:""}
      case DELETE_HAIR_DYE_SUCCESS : return {...state, id:payload, isLoading:false}
      case DELETE_HAIR_DYE_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }
