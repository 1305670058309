export const ADD_HAIR_DYE_START = "ADD_HAIR_DYE_START";
export const ADD_HAIR_DYE_SUCCESS = "ADD_HAIR_DYE_SUCCESS";
export const ADD_HAIR_DYE_ERROR = "ADD_HAIR_DYE_ERROR";

export const GET_ALL_HAIR_DYE_START = "GET_ALL_HAIR_DYE_START";
export const GET_ALL_HAIR_DYE_SUCCESS = "GET_ALL_HAIR_DYE_SUCCESS";
export const GET_ALL_HAIR_DYE_ERROR = "GET_ALL_HAIR_DYE_ERROR";

export const DELETE_HAIR_DYE_START = "DELETE_HAIR_DYE_START";
export const DELETE_HAIR_DYE_SUCCESS = "DELETE_HAIR_DYE_SUCCESS";
export const DELETE_HAIR_DYE_ERROR = "DELETE_HAIR_DYE_ERROR";