import React from "react";
import Header from "../components/Header/Header";
import "../assets/scss/homePage.scss";
import DashboardItem from "../components/dashboardElement/DashboardItem";
import Footer from "../components/Footer/Footer";
import { getRoleAdmin } from "../constants/role/getToken";
const HomePage = () => {
  const roleAdmin = getRoleAdmin();
  return (
    <>
      <Header />
      <div  className="homePage">
        <div className="container">
          <div className="row align-items-center">
      
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/customer"
                img="/images/user.png"
                title="Müştərilər"
              />
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/beautyService"
                img="/images/service.png"
                title="Xidmətlər"
              />
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/allBeautyExpertToService"
                img="/images/expertServiceList.png"
                title="Expert Xidmət"
              />
            </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                <DashboardItem
                  link="/AllCourses"
                  img="/images/course.png"
                  title="Təlimlər"
                />
              </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/AllDept"
                img="/images/debt.png"
                title="Borclar"
              />
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/AllReservation"
                img="/images/calendar.png"
                title="Rezervasiya"
              />
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/beautyExpert"
                img="/images/hairdresser.png"
                title="Expertlər"
              />
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/allRentRoom"
                img="/images/rentOffice.png"
                title="İcarə otaq"
              />
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/allServiceOrder"
                img="/images/addOrder.png"
                title="Sifarişlər"
              />
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/allServiceOrderDetails"
                img="/images/allOrder.png"
                title="Bitmiş Sifarişlər"
              />
            </div>

            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/allOutGoings"
                img="/images/outgoing.png"
                title="Xərclər"
              />
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/allHairDye"
                img="/images/ink.png"
                title="Boyalar"
              />
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-2">
              <DashboardItem
                link="/allSaleHairDye"
                img="/images/saleInk.png"
                title="Satılmış boyalar"
              />
            </div>
            {roleAdmin === "admin" ? (
              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                <DashboardItem
                  link="/statisticAll"
                  img="/images/exchange.png"
                  title="Statistika"
                />
              </div>
            ) : null}

            {/* {roleAdmin === "admin" ? (
              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                <DashboardItem
                  link="/statisticAll"
                  img="/images/garbage.png"
                  title="Silinmiş data"
                />
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
