import React from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { addHairDyeValidation } from "../../constants/yup/validation";
import { addHairDyeAction } from "../../redux/actions/hairDyeAction";

const HairDyeAdd = (s) => {
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        productName:"",
        dyeCode:"",
        firmName:"",
        unitPrice:0
      },
      onSubmit: (values) => {
        
        const data = {
            productName: values.productName,
            dyeCode: values.dyeCode,
            firmName: values.firmName,
            unitPrice: values.unitPrice,
        };
        s.addHairDyeAction(data);
      },
      validationSchema: addHairDyeValidation,
    }
  );
  return (
    <div className="addHairDye">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/color.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Boya əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="productName"
                  name="productName"
                  titleName={"ad"}
                  placeholder="boya adı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.productName}
                  touched={touched.productName}
                  errorMessage={errors.productName}
                  name={"productName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="dyeCode"
                  name="dyeCode"
                  titleName={"kod"}
                  placeholder="boya kodu"
                  inputType="text"
                />
                <ErrorText
                  error={errors.dyeCode}
                  touched={touched.dyeCode}
                  errorMessage={errors.dyeCode}
                  name={"dyeCode"}
                />
                  <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="firmName"
                  name="firmName"
                  titleName={"firma"}
                  placeholder="firma adı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.firmName}
                  touched={touched.firmName}
                  errorMessage={errors.firmName}
                  name={"firmName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="unitPrice"
                  name="unitPrice"
                  titleName={"qiymət"}
                  placeholder="boya qiyməti"
                  inputType="text"
                />
                  <ErrorText
                  error={errors.unitPrice}
                  touched={touched.unitPrice}
                  errorMessage={errors.unitPrice}
                  name={"unitPrice"}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addHairDye: state.addHairDyeAction,
  };
};

export default connect(mapStateToProps, { addHairDyeAction })(
    HairDyeAdd
);
