import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import ErrorText from "../../components/form/ErrorText";
import { connect, useSelector } from "react-redux";
import { getBeautyExpertAllAction } from "../../redux/actions/beautyExpertAction";

import MyInput from "../../components/form/MyInput";
import {
  getSaleHairDyeAction,
  updateSaleHairDyeAction,
} from "../../redux/actions/saleHairDyeAction";
import { getAllHairDyeAction } from "../../redux/actions/hairDyeAction";
import { useParams } from "react-router";

const UpdateSaleHairDye = (props) => {
  const saleHairDye = useSelector((state) => state.getSaleHairDye);
  const { id } = useParams();
  let getId = id;
  const [saleId, setSaleId] = useState(getId);
  useEffect(() => {
    setSaleId(getId);

    return (
      props.getSaleHairDyeAction(saleId),
      props.getAllHairDyeAction(),
      props.getBeautyExpertAllAction()
    );
  }, []);
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        id: saleId,
        hairDyeId: 0,
        expertId: 0,
        quantity: 0,
        expertPayPrice: 0,
        saloonPayPrice: 0,
      },
      onSubmit: (values) => {
        const data = {
          id: saleId,
          hairDyeId:
            values.hairDyeId == 0 || values.hairDyeId == null
              ? saleHairDye.saleHairDyeId.data?.hairDyeId
              : values.hairDyeId,
          expertId:
            values.expertId == 0 || values.expertId == null
              ? saleHairDye.saleHairDyeId.data?.expertId
              : values.expertId,
          quantity:
            values.quantity == "" || values.quantity == null
              ? saleHairDye.saleHairDyeId.data?.quantity
              : values.quantity,
          expertPayPrice:
            values.expertPayPrice == "" || values.expertPayPrice == null
              ? saleHairDye.saleHairDyeId.data?.expertPayPrice
              : values.expertPayPrice,
          saloonPayPrice:
            values.saloonPayPrice == ""|| values.saloonPayPrice == null
              ? saleHairDye.saleHairDyeId.data?.saloonPayPrice
              : values.saloonPayPrice,
        };

        props.updateSaleHairDyeAction(data);
      },
    }
  );
  return (
    <div className="saleHairDyeUpdate">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addOrder.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Boya satışı düzəliş et"}
                btnClass="btn btn-success"
                btnTitle="Düzəliş et"
                btnIco={<AddTask />}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label>Usta</label>
                      <select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="expertId"
                        className="form-control"
                      >
                        <option
                          hidden
                          defaultChecked
                          id="expertId"
                          name="expertId"
                          defaultValue={
                            saleHairDye.saleHairDyeId.data?.expertId
                          }
                        >
                          {
                            props.getBeautyExperts?.beautyExperts?.data?.filter(
                              (s) =>
                                s.id == saleHairDye.saleHairDyeId.data?.expertId
                            )[0]?.firstName
                          }
                        </option>

                        {props.getBeautyExperts?.beautyExperts?.data?.map(
                          (item, index) => (
                            <option
                              id="expertId"
                              name="expertId"
                              key={index}
                              value={item.id}
                            >
                              {item.firstName +
                                " " +
                                item.lastName +
                                " - " +
                                item.profession}
                            </option>
                          )
                        )}
                      </select>
                      <ErrorText
                        error={errors.expertId}
                        touched={touched.expertId}
                        errorMessage={errors.expertId}
                        name={"expertId"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <label>Boya</label>
                      <select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="hairDyeId"
                        className="form-control"
                      >
                        <option
                          id="hairDyeId"
                          name="hairDyeId"
                          hidden
                          defaultValue={
                            saleHairDye.saleHairDyeId.data?.hairDyeId
                          }
                        >
                          {
                            props.getAllHairDye?.allHairDye?.data?.filter(
                              (s) =>
                                s.id ==
                                saleHairDye.saleHairDyeId.data?.hairDyeId
                            )[0]?.productName
                          }
                        </option>

                        {props.getAllHairDye?.allHairDye?.data?.map(
                          (item, index) => (
                            <option
                              id="hairDyeId"
                              name="hairDyeId"
                              key={index}
                              value={item.id}
                            >
                              {item.productName +
                                " " +
                                item.dyeCode +
                                " - " +
                                item.unitPrice +
                                " AZN"}
                            </option>
                          )
                        )}
                      </select>
                      <ErrorText
                        error={errors.hairDyeId}
                        touched={touched.hairDyeId}
                        errorMessage={errors.hairDyeId}
                        name={"hairDyeId"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Say</label>

                      <div
                        style={{ marginTop: "-20px" }}
                        className="myInpCustomer"
                      >
                        <MyInput
                          id="quantity"
                          name="quantity"
                          inputType="text"
                          placeholder="say"
                          defaultVal={saleHairDye.saleHairDyeId.data?.quantity}
                          handleChangeFunc={handleChange}
                          handleBlurFunc={handleBlur}
                        />
                      </div>
                      <ErrorText
                        error={errors.quantity}
                        touched={touched.quantity}
                        errorMessage={errors.quantity}
                        name={"quantity"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Usta ödədiyi</label>

                      <div
                        style={{ marginTop: "-20px" }}
                        className="myInpCustomer"
                      >
                        <MyInput
                          id="expertPayPrice"
                          name="expertPayPrice"
                          inputType="text"
                          defaultVal={
                            saleHairDye.saleHairDyeId.data?.expertPayPrice
                          }
                          placeholder="ustanın ödədiyi məbləğ"
                          handleChangeFunc={handleChange}
                          handleBlurFunc={handleBlur}
                        />
                      </div>
                      <ErrorText
                        error={errors.expertPayPrice}
                        touched={touched.expertPayPrice}
                        errorMessage={errors.expertPayPrice}
                        name={"expertPayPrice"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <label>Salon ödədiyi</label>

                      <div
                        style={{ marginTop: "-20px" }}
                        className="myInpCustomer"
                      >
                        <MyInput
                          id="saloonPayPrice"
                          name="saloonPayPrice"
                          inputType="text"
                          defaultVal={
                            saleHairDye.saleHairDyeId.data?.saloonPayPrice
                          }
                          placeholder="salonun ödədiyi məbləğ"
                          handleChangeFunc={handleChange}
                          handleBlurFunc={handleBlur}
                        />
                      </div>
                      <ErrorText
                        error={errors.saloonPayPrice}
                        touched={touched.saloonPayPrice}
                        errorMessage={errors.saloonPayPrice}
                        name={"saloonPayPrice"}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    updateSaleHairDye: state.updateSaleHairDye,
    getSaleHairDye: state.getSaleHairDye,
    getBeautyExperts: state.getBeautyExperts,
    getAllHairDye: state.allHairDye,
  };
};

export default connect(mapStateToProps, {
  updateSaleHairDyeAction,
  getSaleHairDyeAction,
  getAllHairDyeAction,
  getBeautyExpertAllAction,
})(UpdateSaleHairDye);
