import React, { useEffect } from "react";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { getBeautyExpertAllAction } from "../../redux/actions/beautyExpertAction";
import { getRoleAdmin } from "../../constants/role/getToken";
import { deleteFunc } from "../../constants/delete/DeleteForm";
import {
  deleteSaleHairDyeAction,
  getAllSaleHairDyeAction,
} from "../../redux/actions/saleHairDyeAction";
import { getAllHairDyeAction } from "../../redux/actions/hairDyeAction";

const AllSaleHairDye = (props) => {
  const roleAdmin = getRoleAdmin();

  const dataTableHead = [
    "S-No",
    "Usta adı",
    "Boya adı",
    "Boya kodu",
    "Say",
    "Boya qiyməti",
    "Ümumi qiymət",
    "Usta ödədiyi",
    "Salon ödədiyi",
    "İstifadə tarixi",


    roleAdmin == "admin" ?? "",
  ];

  useEffect(() => {
    return (
      props.getAllSaleHairDyeAction({
        expertName: null,
        productName:null,
        filterMonth: "",
        filterDate: "",
      }),
      props.getBeautyExpertAllAction(),
      props.getAllHairDyeAction()

    );
  }, []);
  const dataCount = props.getAllSaleHairDye.allSaleHairDye?.data;
  const priceList = [];
  const expertPriceList = [];
  var totalSalonPrice = 0;
  var totalExpertPrice = 0;

  dataCount?.map((item) => {
    return (
      priceList.push(item.saloonPayPrice),
      expertPriceList.push(item.expertPayPrice)
    );
  });
  priceList.map((item) => (totalSalonPrice += item));
  expertPriceList.map((item) => (totalExpertPrice += item));

  const dataTableFoot = {
    title: "Salon ödədiyi",
    content: totalSalonPrice.toFixed(2) + " Azn",
  };
  const dataTableFoot2 = {
    title: "Expert ödədiyi",
    content: totalExpertPrice.toFixed(2) + " Azn",
  };
  const { handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      expertName: "",
      productName:"",
      filterMonth: "",
      filterDate: "",
    //   orderDetailId: 0,
    },

    onSubmit: (values) => {
      const data = {
        expertName: values.expertName,
        productName:values.productName,
        filterMonth: values.filterMonth,
        filterDate: values.filterDate,
      };
      props.getAllSaleHairDyeAction(data);
    },
  });

  const getIdFunc = (id) => {
    const myData = {
      id: id,
    };
    deleteFunc({
      deleteActionName: props.deleteSaleHairDyeAction(myData),
      locationReplaceName: "/allSaleHairDye",
    });
  };
  return (
    <div className="saleHairDye">
      <div className="saleHairDyeHead">
        <SubHeader
          backLink="/"
          title="Boya istifadəsi siyahısı"
          btnTitle="Boya istifadəsi əlavə et"
          btnLink="/addSaleHairDye"
        />
      </div>
      <div className="saleHairDyeBody">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
          <form
            style={{ marginBottom: "30px", marginTop: "20px" }}
            onSubmit={handleSubmit}
          >
            <div className="container">
              <div className="row align-items-center">
            
                <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                  <select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="expertName"
                    className="form-control"
                  >
                    <option hidden>Expertlər</option>
                    <option id="expertName" name="expertName" value={""}>
                      Hamısı
                    </option>
                    {props.getBeautyExperts?.beautyExperts?.data?.map(
                      (item, index) => (
                        <option
                          key={index}
                          id="expertName"
                          name="expertName"
                          value={item.firstName + " " + item.lastName}
                        >
                          {item.firstName + " " + item.lastName}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                  <select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="productName"
                    className="form-control"
                  >
                    <option hidden>Boyalar</option>
                    <option id="productName" name="productName" value={""}>
                      Hamısı
                    </option>
                  
                    {props.getAllHairDye?.allHairDye?.data?.map(
                      (item, index) => (
                        <option
                          key={index}
                          id="productName"
                          name="productName"
                          value={item.productName}
                        >
                          {item.productName}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                  <input
                    style={{
                      marginTop: "12px",
                      padding: "10px 25px",
                      borderRadius: "8px",
                      outline: "none",
                      border: "0.8px solid #ccc",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="filterMonth"
                    name="filterMonth"
                    className="form-control"
                    type="month"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                  <input
                    style={{
                      marginTop: "12px",
                      padding: "10px 25px",
                      borderRadius: "8px",
                      outline: "none",
                      border: "0.8px solid #ccc",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="filterDate"
                    name="filterDate"
                    className="form-control"
                    type="date"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                  <button
                    className="btn btn-secondary"
                    style={{ marginTop: "15px", padding: "10px 20px" }}
                    type="submit"
                  >
                    Filter et
                  </button>
                </div>
              </div>
            </div>
          </form>
          {props.getAllSaleHairDye.isLoading ? (
            <LoadingComponent />
          ) : props.getAllSaleHairDye.allSaleHairDye?.data ==
              null ||
            props.getAllSaleHairDye.allSaleHairDye?.data?.length ==
              0 ? (
            <EmptyTableComponent />
          ) : (
            <>
              <DataTableComponent
                tableHeadData={dataTableHead}
                dataTableFoot2={dataTableFoot2}
                dataTableFoot={dataTableFoot}
              >
                {props.getAllSaleHairDye.allSaleHairDye?.data?.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.expertName}</td>
                      <td>{item.productName}</td>
                      <td>{item.dyeCode}</td>
                      <td>{item.quantity} ədəd</td>
                      <td>{item.unitPrice} Azn</td>
                      <td>{item.unitPrice*item.quantity} Azn</td>
                      <td>{item.expertPayPrice} Azn</td>
                      <td>{item.saloonPayPrice} Azn</td>
                      <td>{item.saleDate.substring(0, 10)}</td>
                      {roleAdmin === "admin" ? (
                        <ModifyButtonsComponent
                          delFunc={() => getIdFunc(item.id)}
                          linkUrl={`/UpdateSaleHairDye/${item.id}`} 
                        />
                      ) : null}
                    </tr>
                  )
                )}
              </DataTableComponent>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllSaleHairDye: state.allSaleHairDye,
    getBeautyExperts: state.getBeautyExperts,
    getAllHairDye: state.allHairDye,
    deleteSaleHairDye: state.deleteSaleHairDye,
  };
};

export default connect(mapStateToProps, {
  getAllSaleHairDyeAction,
  getBeautyExpertAllAction,
  getAllHairDyeAction,
  deleteSaleHairDyeAction,
})(AllSaleHairDye);
