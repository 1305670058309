export const ADD_SALE_HAIR_DYE_START = "ADD_SALE_HAIR_DYE_START";
export const ADD_SALE_HAIR_DYE_SUCCESS = "ADD_SALE_HAIR_DYE_SUCCESS";
export const ADD_SALE_HAIR_DYE_ERROR = "ADD_SALE_HAIR_DYE_ERROR";


export const UPDATE_SALE_HAIR_DYE_START = "UPDATE_SALE_HAIR_DYE_START";
export const UPDATE_SALE_HAIR_DYE_SUCCESS = "UPDATE_SALE_HAIR_DYE_SUCCESS";
export const UPDATE_SALE_HAIR_DYE_ERROR = "UPDATE_SALE_HAIR_DYE_ERROR";


export const GET_SALE_HAIR_DYE_START = "GET_SALE_HAIR_DYE_START";
export const GET_SALE_HAIR_DYE_SUCCESS = "GET_SALE_HAIR_DYE_SUCCESS";
export const GET_SALE_HAIR_DYE_ERROR = "GET_SALE_HAIR_DYE_ERROR";


export const GET_ALL_SALE_HAIR_DYE_START = "GET_ALL_SALE_HAIR_DYE_START";
export const GET_ALL_SALE_HAIR_DYE_SUCCESS = "GET_ALL_SALE_HAIR_DYE_SUCCESS";
export const GET_ALL_SALE_HAIR_DYE_ERROR = "GET_ALL_SALE_HAIR_DYE_ERROR";

export const DELETE_SALE_HAIR_DYE_START = "DELETE_SALE_HAIR_DYE_START";
export const DELETE_SALE_HAIR_DYE_SUCCESS = "DELETE_SALE_HAIR_DYE_SUCCESS";
export const DELETE_SALE_HAIR_DYE_ERROR = "DELETE_SALE_HAIR_DYE_ERROR";